import { OrderQuery as RawOrderQuery } from 'Query/Order.query';
import { Field } from 'Util/Query';

/** @namespace Screenpages/Multichannelorder/Query/Order/Query/OrderQuery */
export class OrderQuery extends RawOrderQuery {
    getMultichannelOrderById(orderId) {
        return new Field('getMultichannelOrderById')
            .addArgument('id', 'String!', orderId)
            .addFieldList(this._getOrderItemsFields());
    }

    getOrderListQueryWithDates(dateFrom) {
        return new Field('getMultichannelOrderList')
            .setAlias('getOrderList')
            .addArgument('from_date', 'String', dateFrom)
            .addFieldList(this._getOrderListFieldsAndDeliveryDate(true));
    }

    _getBaseOrderInfoFields(isList) {
        return [
            ...super._getBaseOrderInfoFields(isList),
            'multichannel_order'
        ];
    }

    _getDefaultFields(isList) {
        return [
            ...super._getDefaultFields(isList),
            'promotional_code'
        ];
    }

    _prepareShippingInfo() {
        return new Field('shipping_info')
            .addFieldList(this._prepareShippingInfoFields())
            .addField('delivery_date');
    }

    _getOrderListFieldsAndDeliveryDate(isList) {
        const orderItemsFieldAndDeliveryDate = new Field('items')
            .addFieldList(this._getOrderItemsFieldsAndDeliveryDate(isList));

        return [orderItemsFieldAndDeliveryDate];
    }

    _getOrderItemsFieldsAndDeliveryDate(isList) {
        return [
            this._getBaseOrderInfoField(isList),
            this._getDeliveryDate()
        ];
    }

    _getDeliveryDate() {
        return new Field('shipping_info')
            .addField('delivery_date');
    }
}

export default new OrderQuery();
