import {
    CheckoutQuery as SourceCheckoutQuery
} from 'SourceQuery/Checkout.query';
import { Field } from 'Util/Query';

/** @namespace Rapelli/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery extends SourceCheckoutQuery {
    getEstimateShippingCosts(address, guestCartId) {
        const mutation = new Field('estimateShippingCosts')
            .addArgument('address', 'EstimateShippingCostsAddress!', address)
            .addFieldList(this._getEstimatedShippingFields());

        this._addGuestCartId(guestCartId, mutation);

        mutation.addFieldList([
            new Field('delivery_dates')
                .addFieldList(['deliveryDate', 'latestPickupDate',
                    new Field('timeSlots')
                        .addFieldList(['to', 'from', 'surcharge'])]),
            new Field('delivery_default_price'),
            new Field('no_dates_message')
        ]);

        return mutation;
    }
}

export default new CheckoutQuery();
