/**
 * Google reCAPTCHA compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Children, cloneElement } from 'react';

import RenderWhenVisible from 'Component/RenderWhenVisible';

import ReCaptcha from '../component/ReCaptcha';
import {
    CHECKOUT,
    CUSTOMER_LOGIN,
    EDIT_CUSTOMER_ACCOUNT,
    FIRSTLOGIN,
    FORGOT_PASSWORD,
    NEW_CUSTOMER_ACCOUNT,
    NEWSLETTER,
    PRODUCT_REVIEW
} from '../config/ReCaptcha.config';

export const addReCaptchaToLocation = (location) => (args, callback) => {
    const elem = callback(...args);
    const { children } = elem.props;

    return cloneElement(
        elem,
        null,
        Children.map(children, (child, idx) => {
            if (idx !== location.pos) {
                return child;
            }

            return (
                <>
                    { child }
                    <RenderWhenVisible>
                        <ReCaptcha
                          currentLocation={ location.id }
                        />
                    </RenderWhenVisible>
                </>
            );
        })
    );
};

export default {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            renderSignInForm: addReCaptchaToLocation(CUSTOMER_LOGIN)
        }
    },
    'Screenpages/b2bLogin/Route/B2BFirstTimeLogin/Component/B2BFirstTimeLoginComponent': {
        'member-function': {
            renderFirstTimeForm: addReCaptchaToLocation(FIRSTLOGIN),
            renderChangeDetailsForm: addReCaptchaToLocation(FIRSTLOGIN)
        }
    },
    'Component/MyAccountForgotPassword/Component': {
        'member-function': {
            renderForgotPasswordForm: addReCaptchaToLocation(FORGOT_PASSWORD)
        }
    },
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            renderCreateAccountSignUpInfoFields: addReCaptchaToLocation(NEW_CUSTOMER_ACCOUNT)
        },
    },
    'Component/MyAccountCustomerForm/Component': {
        'member-function': {
            render: addReCaptchaToLocation(EDIT_CUSTOMER_ACCOUNT)
        }
    },
    'Component/ProductReviewForm/Component': {
        'member-function': {
            render: addReCaptchaToLocation(PRODUCT_REVIEW)
        }
    },
    'Component/NewsletterSubscription/Component': {
        'member-function': {
            render: addReCaptchaToLocation(NEWSLETTER)
        }
    },
    'Component/CheckoutBilling/Component': {
        'member-function': {
            render: addReCaptchaToLocation(CHECKOUT)
        }
    }
};
